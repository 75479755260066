
@font-face {
    font-family: 'SuisseIntl-Regular';
    src: local('SuisseIntl-Regular'), url(./assets/fonts/SuisseIntl-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'SuisseIntl-Medium';
    src: local('SuisseIntl-Medium'), url(./assets/fonts/SuisseIntl-Medium.otf) format('opentype');
}
@font-face {
    font-family: 'SuisseWorks-Regular';
    src: local('SuisseWorks-Regular'), url(./assets/fonts/SuisseWorks-Regular.otf) format('opentype');
}

@import "../node_modules/swiper/swiper-bundle.css";
